import React, { useEffect, useContext } from "react";
import Restore from "../../components/authorization/Restore";
import PropTypes from "prop-types";
import RequestController from "../../controllers/authorization/requestController";
import { checkEmail } from "../../helpers/";
import { PageContext } from "../../store/context/page-context";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../store/slice/auth-slice";

const RestoreContainer = ({
  onPasswordRemember,
  displayMessage,
  passwordMode,
  hash,
}) => {
  const context = useContext(PageContext);
  const t = context.translate;
  const analytics = context.analytics;

  const AUTHORIZATION_RESTORE_CONTENT = {
    incorrectEmail: t("Please enter the correct email address"),
    undefinedEmail: t(
      "Sorry, there is no user registered with this email address"
    ),
    passwordLongError: t("Your password should contain at least 6 characters "),
    passwordMatchError: [
      t("The passwords don’t match"),
      t("Please enter the same password twice"),
    ],
    passwordInvalidCharacter: [
      t("Please use only lowercase and"),
      t("uppercase latin letters and numbers"),
    ],
  };

  const { email, password, confirmationPassword, error, btnIsActive } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    updateErrorState("");
  }, [password, confirmationPassword]);

  const updateEmailState = (val) => {
    dispatch(authActions.updateEmailState(val));
  };
  const updatePasswordState = (val) => {
    dispatch(authActions.updatePasswordState(val));
  };
  const updateConfirmationPasswordState = (val) => {
    dispatch(authActions.updateConfirmationPasswordState(val));
  };
  const updateErrorState = (val) => {
    dispatch(authActions.updateErrorState(val));
  };
  const updateBtnState = (val) => {
    dispatch(authActions.updateBtnState(val));
  };

  const handlePasswordRemember = () => {
    dispatch(authActions.updateErrorState(""));
    onPasswordRemember();
  };

  const checkPasswordFields = () => {
    if (password !== confirmationPassword) {
      analytics.sendNewPasswordMathErrorEvent();
      updateErrorState("passwordMatchError");
    } else if (password.length < 6) {
      analytics.sendNewPasswordCharacterErrorEvent();
      updateErrorState("passwordLongError");
    } else if (!/^[a-zA-Z0-9]+$/.test(password)) {
      analytics.sendNewPasswordCharacterErrorEvent();
      updateErrorState("passwordInvalidCharacter");
    } else {
      updateErrorState("");
      return true;
    }
  };

  const onPasswordSubmit = (e) => {
    e.preventDefault();
    if (!!checkPasswordFields() && hash.length > 0) {
      updateBtnState(false);
      const requestController = new RequestController();
      requestController
        .saveRequest(
          encodeURIComponent(hash),
          encodeURIComponent(confirmationPassword)
        )
        .then(() => {
          analytics.sendNewPasswordSuccessEvent();
          updateBtnState(true);
          displayMessage({ display: true, error: false, status: true });
        })
        .catch(() => {
          updateBtnState(true);
          displayMessage({ display: true, error: true, status: true });
        });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (checkEmail(email)) {
      handleRestorePassword(email);
    } else {
      analytics.sendForgotEmailErrorEvent();
      updateErrorState("incorrectEmail");
    }
  };

  const handleRestorePassword = (email) => {
    updateBtnState(false);
    const requestController = new RequestController();
    requestController
      .restoreRequest(email)
      .then(() => {
        analytics.sendForgotRequestLinkEvent();
        displayMessage({ display: true, error: false, status: false });
      })
      .catch((err) => {
        if (err.message === "user.not.found") {
          analytics.sendForgotUndefinedEmailEvent();
          updateErrorState("undefinedEmail");
        } else {
          analytics.sendForgotEmailErrorEvent();
          updateErrorState("incorrectEmail");
        }
        updateBtnState(true);
      });
  };

  return (
    <Restore
      btnActive={btnIsActive}
      error={error ? AUTHORIZATION_RESTORE_CONTENT[error] : false}
      handleRememberClick={handlePasswordRemember}
      handleSaveClick={onPasswordSubmit}
      email={email}
      password={password}
      confirmationPassword={confirmationPassword}
      onEmailChange={updateEmailState}
      onConfirmationPasswordChange={updateConfirmationPasswordState}
      onPasswordChange={updatePasswordState}
      handleSubmit={handleFormSubmit}
      sendPassword={passwordMode}
    />
  );
};

RestoreContainer.propTypes = {
  onPasswordRemember: PropTypes.func.isRequired,
  displayMessage: PropTypes.func.isRequired,
  passwordMode: PropTypes.bool.isRequired,
  hash: PropTypes.string,
};

export default RestoreContainer;
