import React, { useContext } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { PageContext } from "../../../store/context/page-context";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/slice/auth-slice";

const Message = ({ status, error, onSignIn, onTryAgain }) => {
  const context = useContext(PageContext);
  const t = context.translate;
  const isCn = context.isCn;
  const dispatch = useDispatch();

  const clearAndHandle = (execute) => {
    dispatch(authActions.resetAllStates());
    execute();
  };

  const getStatusView = (error) => {
    return (
      <>
        <p className="PgAuth-Message__title">
          {error
            ? t("This link for changing a password is outdated or not found.")
            : t("You have successfully changed your password to AMZScout.")}
        </p>
        <div className="PgAuth-Message__description">
          {error ? (
            <>
              {t("Please")}{" "}
              <button
                className="PgAuth-Message__button"
                onClick={() => clearAndHandle(onTryAgain)}
              >
                {t("try again")}
              </button>
            </>
          ) : (
            <>
              {t("Please")}
              {!isCn && " "}
              {!isCn && (
                <button
                  className="PgAuth-Message__button"
                  onClick={() => clearAndHandle(onSignIn)}
                >
                  {t("sign in")}
                </button>
              )}
              {!isCn && " "}
              {t("with your new password")}
              {isCn && (
                <button
                  className="PgAuth-Message__button"
                  onClick={() => clearAndHandle(onSignIn)}
                >
                  {t("sign in")}
                </button>
              )}
              .
            </>
          )}
        </div>
      </>
    );
  };

  const getForgotView = () => {
    return (
      <>
        <p className="PgAuth-Message__title">
          {t(
            "Please check your email. We have sent a link that you can follow to reset your password"
          )}
        </p>
        <p className="PgAuth-Message__or">{t("or")}</p>
        <div className="PgAuth-Message__description">
          {!isCn && (
            <button
              className="PgAuth-Message__button"
              onClick={() => clearAndHandle(onSignIn)}
            >
              {t("Sign in")}
            </button>
          )}{" "}
          {t("with current password")}{" "}
          {isCn && (
            <button
              className="PgAuth-Message__button"
              onClick={() => clearAndHandle(onSignIn)}
            >
              {t("Sign in")}
            </button>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="PgAuth-Message">
      {status ? getStatusView(error) : getForgotView()}
    </div>
  );
};

Message.propTypes = {
  status: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  onSignIn: PropTypes.func.isRequired,
  onTryAgain: PropTypes.func.isRequired,
};

export default Message;
