import React from "react";
import "./index.scss";
import SVGLogoBlack from "../../assets/images/vector/components/common/logo_amzscout_black.svg";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../helpers";
import TabSection from "./TabSection";

const Form = ({ children, formState, withTabs, handleFormSwitch }) => {
  const Logo = () => {
    return (
      <img
        className={getTrueClasses(
          "PgAuth-Form__logo",
          withTabs && "PgAuth-Form__logo_ext"
        )}
        src={SVGLogoBlack}
        alt="logo"
        width="90"
        height="35"
      />
    );
  };

  return (
    <div className="PgAuth-Body">
      {withTabs && (
        <>
          <Logo />
          <TabSection isSingIn={formState} onSwitch={handleFormSwitch} />
        </>
      )}
      <div
        className={getTrueClasses(
          "PgAuth-Form",
          formState && "PgAuth-Form_message",
          withTabs && "PgAuth-Form_tab"
        )}
      >
        {!withTabs && <Logo />}
        {children}
      </div>
    </div>
  );
};

Form.propTypes = {
  formState: PropTypes.bool.isRequired,
  withTabs: PropTypes.bool,
  handleFormSwitch: PropTypes.func.isRequired,
};

export default Form;
