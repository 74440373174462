import React, { useContext } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../helpers";
import { PageContext } from "../../../store/context/page-context";
import { authActions } from "../../../store/slice/auth-slice";
import { useDispatch } from "react-redux";

const TabSection = ({ isSingIn, onSwitch }) => {
  const context = useContext(PageContext);
  const t = context.translate;
  const dispatch = useDispatch();
  const handleSwitch = (status) => {
    dispatch(authActions.resetAllStates());
    onSwitch(status);
  };

  return (
    <div className="PgAuth-TabSection">
      <button
        className={getTrueClasses(
          "PgAuth-TabSection__button",
          "clearBtn",
          isSingIn && "PgAuth-TabSection__button_selected"
        )}
        onClick={() => handleSwitch(true)}
      >
        {t("Sign up")}
      </button>
      <button
        className={getTrueClasses(
          "PgAuth-TabSection__button",
          "clearBtn",
          !isSingIn && "PgAuth-TabSection__button_selected"
        )}
        onClick={() => handleSwitch(false)}
      >
        {t("Sign in")}
      </button>
    </div>
  );
};

TabSection.propTypes = {
  isSingIn: PropTypes.bool.isRequired,
  onSwitch: PropTypes.func.isRequired,
};

export default TabSection;
