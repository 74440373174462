import { setCookie } from "../../helpers/index";

class AuthorizationController {
  handleSuccessAuth(params, response) {
    if (!response || !params) {
      return this.rejectParameter("Response or Params");
    }
    if (typeof window !== "undefined") {
      setCookie("h", response.hash, 30);
      const message = {
        event: "oauth.done",
        token: response.hash,
        userId: response.id,
        email: response.email,
      };
      if (params.software) {
        const channel = new BroadcastChannel("amz-auth");
        channel.postMessage(message);
        window.parent.postMessage(message, "*");
        if (params.returnUrl && params.software !== "WEB") {
          window.location.replace(params.returnUrl);
        } else {
          window.close();
        }
      } else {
        window.location.replace("/app/");
      }
    }
  }
  rejectParameter(name) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(`'${name}' parameter is required`);
  }
}

export default AuthorizationController;
