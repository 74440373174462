import track from "../../tracking/analytics";
import { trackGa } from "../../tracking/googleAnalytics";

class AnalyticsController {
  constructor() {
    this.params = new URL(window.location.href).searchParams;
    this.software = this.params.get("software") || "LANDING";
    this.states = [
      "SignUpForm",
      "SignInForm",
      "ForgotPassword",
      "NewPassword",
      "SocialForm",
    ];
  }

  createCategory(state) {
    this.category = `${this.software}|${state}`;
  }

  createSendEvent(state, action, label, sendToGA) {
    this.createCategory(state);
    this.send(action, label, sendToGA);
  }

  send(action, label, sendToGa) {
    sendToGa &&
      trackGa(action, {
        category: this.category,
        label: label,
        transport_type: "beacon",
      });
    track(this.category, action, label, null, this.software);
  }

  newSend(category, action, label, value) {
    trackGa(action, {
      category: category,
      label: label,
      value: value,
      transport_type: "beacon",
    });
    track(category, action, label, value, this.software);
  }

  createNewSendEvent(state, action, label, value) {
    const category = `NEW|${this.software}|${state}`;
    this.newSend(category, action, label, value);
  }

  sendEmailErrorEvent(state) {
    return this.createSendEvent(state, "error_incorrect_email", "email", true);
  }

  sendEmailUsedEvent() {
    return this.createSendEvent(
      this.states[0],
      "error_registered_email",
      "email",
      true
    );
  }

  sendSignUpShowEvent() {
    return this.createSendEvent(this.states[0], "show", "", true);
  }

  sendSignUpSuccessEvent(type) {
    return this.createSendEvent(this.states[0], "success", type, true);
  }

  sendSignUpErrorEvent() {
    return this.sendEmailErrorEvent(this.states[0]);
  }

  sendSignInShowEvent() {
    return this.createSendEvent(this.states[1], "show", "", true);
  }

  sendSignInSuccessEvent(type) {
    return this.createSendEvent(this.states[1], "success", type, true);
  }

  sendSignInErrorEvent() {
    return this.sendEmailErrorEvent(this.states[1]);
  }

  sendForgotShowEvent() {
    return this.createSendEvent(this.states[2], "show", "", true);
  }

  sendForgotEmailErrorEvent() {
    return this.sendEmailErrorEvent(this.states[2]);
  }

  sendForgotUndefinedEmailEvent() {
    return this.createSendEvent(this.states[2], "error_not_found", "", true);
  }

  sendNewEmailEvent(email) {
    return this.createNewSendEvent(
      this.states[1],
      "new_email_event",
      "email",
      email
    );
  }

  sendForgotExpiredLinkEvent() {
    return this.createSendEvent(
      this.states[2],
      "error_outdated_link",
      "",
      true
    );
  }

  sendForgotRequestLinkEvent() {
    return this.createSendEvent(this.states[2], "success", "", true);
  }

  sendNewPasswordShowEvent() {
    return this.createSendEvent(this.states[3], "show", "", true);
  }

  sendNewPasswordMathErrorEvent() {
    return this.createSendEvent(
      this.states[3],
      "error_passwords_dont_match",
      "",
      true
    );
  }

  sendNewPasswordCharacterErrorEvent() {
    return this.createSendEvent(
      this.states[3],
      "error_at_least_6_characters",
      "",
      true
    );
  }

  sendNewPasswordSuccessEvent() {
    return this.createSendEvent(this.states[3], "success", "", true);
  }

  sendSocialPopUpErrorEvent(action) {
    return this.createSendEvent(
      this.states[4],
      action || "error_undefined",
      "",
      true
    );
  }
}

export default AnalyticsController;
