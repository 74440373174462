import React from "react";
import PropTypes from "prop-types";

const Error = ({ children, secondLine, withBottom }) => {
  let errorClass = "PgAuth-Error";

  if (secondLine) {
    errorClass += ` ${errorClass}_secondLine`;
  }

  if (withBottom) {
    errorClass += ` ${errorClass}_withBottom`;
  }

  return <div className={errorClass}>{children}</div>;
};

Error.propTypes = {
  withBottom: PropTypes.bool,
};

export default Error;
