import React, { useContext } from "react";
import Header from "../Header";
import "./index.scss";
import Field from "../Field";
import PropTypes from "prop-types";
import Button from "../Button";
import Error from "../Error";
import OptionButton from "../OptionButton";
import { PageContext } from "../../../store/context/page-context";
import { getTrueClasses } from "../../../helpers";

const Restore = ({
  error,
  sendPassword,
  handleRememberClick,
  email,
  password,
  confirmationPassword,
  onEmailChange,
  onConfirmationPasswordChange,
  onPasswordChange,
  handleSubmit,
  btnActive,
  handleSaveClick,
}) => {
  const context = useContext(PageContext);
  const t = context.translate;

  let errorBlock = <Error>{error}</Error>;

  if (typeof error === "object") {
    errorBlock = error.map((e, index) => (
      <Error
        secondLine={index >= 1}
        key={new Date().getTime() * Math.floor(Math.random() * 999)}
      >
        {e}
      </Error>
    ));
  }

  return (
    <div className="PgAuth-Restore">
      {!sendPassword ? (
        <React.Fragment>
          <Header title={t("Forgot password")} />
          <p className="PgAuth-Restore__description">
            {t(
              "Please enter your email address in order to reset your password"
            )}
          </p>
          <form className="PgAuth-Restore-form">
            <Field
              type="text"
              placeHolder="example@gmail.com"
              label={`${t("Your email")}:`}
              alert={error}
              currentValue={email}
              handleChange={(e) => onEmailChange(e.target.value)}
              handleSuccess={handleSubmit}
            />
            {error ? <Error>{error}</Error> : null}
            <Button
              customClass={getTrueClasses(
                "PgAuth-Restore-form__btn",
                !btnActive && "PgAuth-Button_disabled"
              )}
              handleClick={handleSubmit}
            >
              {t("Continue")}
            </Button>
          </form>
          <OptionButton
            onClick={handleRememberClick}
            customClass="PgAuth-Restore__option"
          >
            {t("I remember my password")}
          </OptionButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p className="PgAuth-Restore__description PgAuth-Restore__description_bg">
            {t("Please set up a new password for your account")}
          </p>
          <form className="PgAuth-Restore-form">
            <Field
              handleChange={(e) => onPasswordChange(e.target.value)}
              currentValue={password}
              label={t("New password:")}
              placeHolder="******************"
              type="password"
              alert={error}
              handleSuccess={handleSaveClick}
              compact
            />
            <Field
              handleChange={(e) => onConfirmationPasswordChange(e.target.value)}
              currentValue={confirmationPassword}
              label={t("Repeat new password:")}
              placeHolder="******************"
              type="password"
              alert={error}
              handleSuccess={handleSaveClick}
              compact
            />
            {error ? errorBlock : null}
            <Button
              handleClick={handleSaveClick}
              customClass={getTrueClasses(
                "PgAuth-Restore-form__btn PgAuth-Restore-form__btn_bg",
                !btnActive && "PgAuth-Button_disabled"
              )}
            >
              {t("Save password")}
            </Button>
          </form>
        </React.Fragment>
      )}
    </div>
  );
};

Restore.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
  sendPassword: PropTypes.bool,
  handleRememberClick: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  confirmationPassword: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onConfirmationPasswordChange: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  btnActive: PropTypes.bool.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
};

export default Restore;
