import React from "react";
import "./index.scss";
import PropTypes from "prop-types";

const OptionButton = ({ children, statusError, customClass, onClick }) => {
  let classes = "PgAuth-OptionButton clearBtn";

  if (statusError) {
    classes += " PgAuth-OptionButton_alert";
  }

  if (customClass) {
    classes += " " + customClass;
  }

  return (
    <button onClick={onClick} className={classes}>
      {children}
    </button>
  );
};

OptionButton.propTypes = {
  statusError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
  customClass: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default OptionButton;
