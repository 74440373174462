import React, { useContext } from "react";
import Social from "../../components/authorization/Social";
import SocialModel from "../../models/authorization/socialModel";
import PropTypes from "prop-types";
import { PageContext } from "../../store/context/page-context";
import WeiboController from "../../controllers/authorization/weiboController";

const SocialContainer = ({ onSuccess, regState }) => {
  const context = useContext(PageContext);
  const analytics = context.analytics;
  const weiboController = new WeiboController();

  const socialModel = new SocialModel();

  const handleLogin = (data, provider) => {
    if (!data.accessToken) {
      handleFail(
        new Error(
          provider === "fb" ? "popup_closed_by_user" : "access_token_error"
        )
      );
      return;
    }
    socialModel
      .authorize(data, provider)
      .then((res) =>
        res.json().then((parsedRes) => {
          analytics.sendSignInSuccessEvent(provider);
          onSuccess(parsedRes);
        })
      )
      .catch((err) => {
        handleFail(err);
      });
  };

  const handleWeiboLogin = () => {
    weiboController
      .authorize()
      .then((auth) => {
        return handleLogin(auth, auth.provider);
      })
      .catch((err) => {
        handleFail(err);
      });
  };

  const handleFail = (event) => {
    analytics.sendSocialPopUpErrorEvent(event?.error || event?.message);
  };

  return (
    <Social
      logTitle={`Sign ${regState ? "up" : "in"}`}
      onLogin={handleLogin}
      onSocialFail={handleFail}
      onWeibo={handleWeiboLogin}
    />
  );
};

SocialContainer.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  regState: PropTypes.bool.isRequired,
};

export default SocialContainer;
