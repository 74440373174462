import React from "react";
import GIFLoader from "../../../assets/images/gif/loader.gif";
import "./index.scss";

const Loader = () => {
  return (
    <div className="PgAuth-Loader">
      <img className="PgAuth-Loader__img" src={GIFLoader} alt="loader" />
    </div>
  );
};

export default Loader;
