import React, { useContext } from "react";
import "./index.scss";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useGoogleLogin } from "@react-oauth/google";
import PropTypes from "prop-types";
import TrackedLink from "../../Analytics/TrackedLink";
import { PageContext } from "../../../store/context/page-context";

const Social = ({ onLogin, onSocialFail, onWeibo, logTitle }) => {
  const context = useContext(PageContext);
  const t = context.translate;

  const login = useGoogleLogin({
    onSuccess: (data) => {
      data.accessToken = data.access_token;
      onLogin(data, "google");
    },
    onError: (errorResponse) => onSocialFail(errorResponse),
    flow: "implicit",
  });

  return (
    <div className="PgAuth-Social">
      <div className="PgAuth-Social-header">
        <p className="PgAuth-Social-header__title">{t("or")}</p>
      </div>
      <div className="PgAuth-Social-body">
        <button
          onClick={() => login()}
          className="PgAuth-Social__btn PgAuth-Social__btn_google"
        >
          {t(`${logTitle} with Google`)}
        </button>
        {t("code") === "zh" ? (
          <button
            onClick={onWeibo}
            className="PgAuth-Social__btn PgAuth-Social__btn_weibo"
          >
            {t(`${logTitle} with Weibo`)}
          </button>
        ) : (
          <FacebookLogin
            appId={process.env.AUTH_FACEBOOK_ID}
            cookie={true}
            xfbml={true}
            callback={(data) => onLogin(data, "fb")}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="PgAuth-Social__btn PgAuth-Social__btn_facebook"
              >
                {t(`${logTitle} with Facebook`)}
              </button>
            )}
          />
        )}
      </div>
      <div className="PgAuth-Social-footer">
        <p className="PgAuth-Social__terms">
          {t("By continuing, you agree to the")}{" "}
          <TrackedLink
            target
            action="CTA"
            category="authorizationLP"
            path="/privacy-policy/"
          >
            {t("Privacy Policy")}
          </TrackedLink>{" "}
          {t("and")}{" "}
          <TrackedLink
            target
            action="CTA"
            category="authorizationLP"
            path="/terms-of-use/"
          >
            {t("Terms and Conditions")}
          </TrackedLink>
        </p>
        <p className="PgAuth-Social-footer__security">
          {t("Your information is secured using SSL encryption")}
        </p>
      </div>
    </div>
  );
};

Social.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onSocialFail: PropTypes.func.isRequired,
  onWeibo: PropTypes.func.isRequired,
  logTitle: PropTypes.string.isRequired,
};

export default Social;
