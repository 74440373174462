import React, { useEffect } from "react";
import Loader from "../../components/authorization/Loader";
import PropTypes from "prop-types";
import RequestController from "../../controllers/authorization/requestController";

const LoaderContainer = ({ hash, onSuccess, onFail }) => {
  useEffect(() => {
    const requestController = new RequestController();

    requestController
      .checkRequest(hash)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFail();
      });
  }, []);

  return <Loader />;
};

LoaderContainer.propTypes = {
  hash: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

export default LoaderContainer;
