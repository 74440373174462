import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Header = ({ title, buttonTxt, onClick }) => {
  return (
    <div className="PgAuth-Header">
      <span className="PgAuth-Header__title">{title}</span>
      {buttonTxt ? (
        <button onClick={onClick} className="PgAuth-Header__btn">
          {buttonTxt}
        </button>
      ) : null}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTxt: PropTypes.string,
  onClick: PropTypes.func,
};

export default Header;
