class SocialModel {
  authorize(data, provider) {
    const body = {
      token: data.accessToken,
      software: "WEB",
      ...(data.userID && { userId: data.userID }),
    };
    return fetch(process.env.AUTH_HOST + "/v1/oauth/" + provider, {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=utf-8",
      },
      method: "POST",
      body: JSON.stringify(body),
    });
  }
}

export default SocialModel;
