import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../../../store/context/page-context";
import { getTrueClasses } from "../../../helpers";
import "./index.scss";

const Progress = ({ seconds, signUpState }) => {
  const context = useContext(PageContext);
  const t = context.translate;
  const getTextBySoftware = (software) => {
    switch (software) {
      case "SCOUT_EXT_PRO":
        return " PRO";
      case "SCOUT_EXT_PRO_NEW":
        return " PRO AI";
      default:
        return "";
    }
  };
  const text = t(
    `You will be redirected to AMZScout${getTextBySoftware(context.software)} in`
  );
  const isCn = context.isCn;

  return (
    <div
      className={getTrueClasses(
        "PgAuth-Progress",
        !signUpState && "PgAuth-Progress_small"
      )}
    >
      {signUpState && (
        <p className="PgAuth-Progress__title">
          {t("We’ve sent the password to your email address")}
        </p>
      )}
      <div
        className={getTrueClasses(
          "PgAuth-Progress-progress",
          !signUpState && "PgAuth-Progress-progress_small"
        )}
      >
        <div className="PgAuth-Progress-progress__line" />
      </div>
      <p className="PgAuth-Progress__title">
        {isCn ? seconds : ""} {text} {!isCn ? seconds : ""} {t("seconds")}
      </p>
    </div>
  );
};

Progress.propTypes = {
  seconds: PropTypes.number.isRequired,
  signUpState: PropTypes.bool.isRequired,
};

export default Progress;
