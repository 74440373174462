class RestoreModel {
  constructor() {
    this.requestHeaders = {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
    };
  }
  request(email) {
    return fetch(
      process.env.AUTH_HOST +
        "/v2/users/password/restore/" +
        `?email=${encodeURIComponent(email)}`,
      {
        headers: this.requestHeaders,
        method: "POST",
      }
    );
  }
  check(hash) {
    return fetch(
      process.env.AUTH_HOST +
        "/v2/users/password/change/validate/" +
        `?hash=${encodeURIComponent(hash)}`,
      {
        headers: this.requestHeaders,
        method: "GET",
      }
    );
  }
  change(hash, password) {
    return fetch(process.env.AUTH_HOST + "/v2/users/password/change/", {
      headers: this.requestHeaders,
      method: "POST",
      body: JSON.stringify({
        hash,
        newPassword: password,
      }),
    });
  }
}

export default RestoreModel;
