import RestoreModel from "../../models/authorization/restoreModel";
import SignModel from "../../models/authorization/signModel";

class RequestController {
  constructor() {
    this.authorizationRestoreModel = new RestoreModel();
    this.authorizationSignModel = new SignModel();
  }

  registerRequest(email, software) {
    return this.authorizationSignModel
      .register(email, software)
      .then((res) => {
        return res.text();
      })
      .then((data) => {
        return data ? JSON.parse(data) : null;
      });
  }

  loginRequest(email, password) {
    return this.authorizationSignModel
      .login(email, password)
      .then((res) => {
        return res.json();
      })
      .then((parsedData) => {
        if (parsedData && parsedData.message === "user.not.found") {
          throw new Error(parsedData.message);
        }
        if (!parsedData) {
          throw new Error("Server error");
        } else {
          return parsedData;
        }
      });
  }

  checkRequest(hash) {
    return this.authorizationRestoreModel
      .check(hash)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((status) => {
        if (status === true) {
          return status;
        } else {
          throw new Error("Invalid hash");
        }
      });
  }

  restoreRequest(email) {
    return this.authorizationRestoreModel.request(email).then((res) => {
      if (res.status === 200 || res.status === 202) {
        return "ok";
      } else {
        return res.json().then((parsedData) => {
          throw new Error(parsedData.message);
        });
      }
    });
  }

  saveRequest(hash, password) {
    return this.authorizationRestoreModel.change(hash, password).then((res) => {
      if (res.status === 200 || res.status === 202) {
        return "ok";
      } else {
        return res.json().then((parsedData) => {
          throw new Error(parsedData.message);
        });
      }
    });
  }
}

export default RequestController;
