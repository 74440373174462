import React, { useContext, useRef } from "react";
import Sign from "../../components/authorization/Sign";
import PropTypes from "prop-types";
import { checkEmail } from "../../helpers/";
import RequestController from "../../controllers/authorization/requestController";
import { PageContext } from "../../store/context/page-context";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../store/slice/auth-slice";
// import AnalyticsController from "../../controllers/authorization/analyticsController";
//
// const analyticsController =
//   typeof window !== "undefined" ? new AnalyticsController() : {};

const SignContainer = ({
  regState,
  onSuccess,
  onSwitchForm,
  onPasswordForgotten,
}) => {
  const context = useContext(PageContext);
  const t = context.translate;
  const analytics = context.analytics;
  const inputRef = useRef(null);

  const AUTHORIZATION_SIGN_CONTENT = {
    incorrectEmail: t("Please enter the correct email address"),
    alreadyUseEmail: [
      t(
        "This email address is already registered. Please use another email address to sign up, or use this email address to"
      ),
      t("sign in"),
    ],
    invalidPassword: t("Please enter the correct email address and password"),
  };

  const requestController = new RequestController();

  const { email, password, error, passwordFieldType, btnIsActive } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const updateEmailState = (val) => {
    dispatch(authActions.updateEmailState(val));
  };
  const updatePasswordState = (val) => {
    dispatch(authActions.updatePasswordState(val));
  };
  const updateErrorState = (val) => {
    dispatch(authActions.updateErrorState(val));
  };
  const updateBtnState = (val) => {
    dispatch(authActions.updateBtnState(val));
  };

  const switchPasswordType = (event) => {
    if (
      event.target.tagName === "IMG" ||
      event.target.id === "authShowPasswordButton"
    ) {
      event.preventDefault();
      dispatch(authActions.togglePasswordField());
    }
  };

  const clearAndHandle = (execute) => {
    dispatch(authActions.resetAllStates());
    execute();
  };

  const handleEmailFormChange = (event) => {
    updateEmailState(event.target.value);
  };

  const handlePasswordFormChange = (event) => {
    updatePasswordState(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // if (inputRef.current.value) {
    //   analyticsController.sendNewEmailEvent(inputRef.current.value);
    // } else {
    updateBtnState(false);

    if (regState) {
      handleSignUpSubmit(email);
    } else {
      handleSignInSubmit(email, password);
    }
    // }
  };

  const handleSignInSubmit = (email, password) => {
    if (checkEmail(email) && password.trim().length > 0) {
      updateErrorState("");
      requestController
        .loginRequest(email, password)
        .then((data) => {
          analytics.sendSignInSuccessEvent("email");
          onSuccess(data);
        })
        .catch((err) => {
          analytics.sendSignInErrorEvent();
          updateBtnState(true);
          updateErrorState("invalidPassword");
        });
    } else {
      analytics.sendSignInErrorEvent();
      updateErrorState("invalidPassword");
      updateBtnState(true);
    }
  };

  const handleSignUpSubmit = (email) => {
    if (checkEmail(email)) {
      updateErrorState("");
      requestController
        .registerRequest(email, "LANDING")
        .then((parsedData) => {
          if (!parsedData) {
            analytics.sendEmailUsedEvent();
            updateErrorState("alreadyUseEmail");
            updateBtnState(true);
          } else {
            analytics.sendSignUpSuccessEvent("email");
            onSuccess(parsedData);
          }
        })
        .catch(() => {
          analytics.sendSignUpErrorEvent();
          updateErrorState("incorrectEmail");
          updateBtnState(true);
        });
    } else {
      analytics.sendSignUpErrorEvent();
      updateErrorState("incorrectEmail");
      updateBtnState(true);
    }
  };

  return (
    <Sign
      inputRef={inputRef}
      email={email}
      password={password}
      passwordField={passwordFieldType}
      btnActive={btnIsActive}
      onShowPassword={switchPasswordType}
      onEmailChange={handleEmailFormChange}
      onPasswordChange={handlePasswordFormChange}
      reg={regState}
      handleBtnClick={() => clearAndHandle(onSwitchForm)}
      error={error ? AUTHORIZATION_SIGN_CONTENT[error] : false}
      onSubmit={handleSubmit}
      handleForgottenSwitch={() => clearAndHandle(onPasswordForgotten)}
    />
  );
};

SignContainer.propTypes = {
  regState: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSwitchForm: PropTypes.func.isRequired,
  onPasswordForgotten: PropTypes.func.isRequired,
};

export default SignContainer;
