import React, { useContext } from "react";
import PropTypes from "prop-types";
import SVGInputSHowIcon from "../../../assets/images/vector/pages/authorization/inputShowIcon.svg";
import { PageContext } from "../../../store/context/page-context";
import "./index.scss";

const Field = ({
  inputRef,
  type,
  label,
  placeHolder,
  children,
  alert,
  compact,
  currentValue,
  handleChange,
  handleView,
  handleSuccess,
}) => {
  const context = useContext(PageContext);
  const t = context.translate;

  let labelClass = "PgAuth-Field-field__label";
  let inputClass = "PgAuth-Field-field__input";

  if (compact) {
    labelClass += ` ${labelClass}_compact`;
    inputClass += ` ${inputClass}_compact`;
  }

  if (alert) {
    inputClass += " PgAuth-Field-field__input_alert";
  }

  const handleEnter = (event) => {
    if (event.charCode === 13) {
      handleSuccess(event);
    }
  };

  return (
    <>
      <div className="PgAuth-Field-field">
        {/* <input*/}
        {/*  ref={inputRef}*/}
        {/*  className={inputClass}*/}
        {/*  type={type}*/}
        {/*  placeholder={placeHolder}*/}
        {/*  onKeyPress={handleEnter}*/}
        {/* />*/}
        <label className={labelClass}>{label}</label>
        <input
          className={inputClass}
          type={type}
          placeholder={placeHolder}
          autoComplete="on"
          value={currentValue}
          onChange={handleChange}
          onKeyPress={handleEnter}
        />
        {handleView ? (
          <button
            id="authShowPasswordButton"
            className={`PgAuth-Field-field__showBtn ${
              type === "text" && "PgAuth-Field-field__showBtn_open"
            } clearBtn`}
            onClick={handleView}
          >
            <img src={SVGInputSHowIcon} alt={t("Show Password")} />
          </button>
        ) : null}
      </div>
      {children}
    </>
  );
};

Field.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  children: PropTypes.array,
  alert: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
  compact: PropTypes.bool,
  currentValue: PropTypes.string,
  handleChange: PropTypes.func,
  handleView: PropTypes.func,
  handleSuccess: PropTypes.func.isRequired,
};

export default Field;
