import React, { useState, useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import Form from "../components/authorization/Form";
import Layout from "../layouts/LayoutEmpty";
import SignContainer from "../containers/authorization/SignContainer";
import SocialContainer from "../containers/authorization/SocialContainer";
import ProgressContainer from "../containers/authorization/ProgressContainer";
import Message from "../components/authorization/Message";
import AuthorizationController from "../controllers/authorization/authorizationController";
import RestoreContainer from "../containers/authorization/RestoreContainer";
import LoaderContainer from "../containers/authorization/LoaderContainer";
import { PageContext } from "../store/context/page-context";
import AnalyticsController from "../controllers/authorization/analyticsController";
import ReduxProvider from "../store/providers/ReduxProvider";

const authorizationController = new AuthorizationController();
const analyticsController =
  typeof window !== "undefined" ? new AnalyticsController() : {};

const Authorization = () => {
  const { t } = useTranslation();

  const [getHash, setHash] = useState("");
  const [getLoaderMode, setLoaderMode] = useState(false);
  const [getPasswordMode, setPasswordMode] = useState(false);
  const [getResponse, setResponse] = useState({});
  const [getFormState, setFormState] = useState(true);
  const [getRestoreState, setRestoreState] = useState(false);
  const [getRedirectState, setRedirectState] = useState(false);
  const [getParams, setParams] = useState({
    software: "",
    returnUrl: "",
  });
  const [getMessageState, setMessageState] = useState({
    display: false,
    status: false,
    error: false,
  });
  const [getIsLoading, setIsLoading] = useState(false);

  const updateMessageDisplay = (args) => {
    setMessageState((prevState) => {
      return {
        ...prevState,
        ...args,
      };
    });
  };

  const switchToPasswordRestore = () => {
    setPasswordMode(true);
    setRestoreState(true);
    setLoaderMode(false);
  };

  const declinePasswordRestore = () => {
    setLoaderMode(false);
    updateMessageDisplay({
      display: true,
      status: true,
      error: true,
    });
  };

  const handleSwitchSignInAndRestore = (status) => {
    setRestoreState(status);
    updateMessageDisplay({ display: false });
    setPasswordMode(false);
    setFormState(false);
  };

  const handleSuccessAuth = () => {
    return authorizationController.handleSuccessAuth(getParams, getResponse);
  };

  const switchFormState = (status) => {
    setFormState((prevState) => status ?? !prevState);
  };

  const onRegistrationSuccess = (res) => {
    if (!res.isNew) {
      setFormState(false);
    }
    setResponse({ ...res });
    setRedirectState(true);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location);
      setParams({
        software: url.searchParams.get("software"),
        returnUrl: url.searchParams.get("url"),
      });
      const hash = url.searchParams.get("hash");
      if (hash) {
        setHash(hash);
        setLoaderMode(true);
      }
      if (url.searchParams.get("state") === "login") {
        setFormState(false);
      }
    }
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (
      !getLoaderMode &&
      !getRestoreState &&
      !getPasswordMode &&
      getIsLoading
    ) {
      return getFormState
        ? analyticsController.sendSignUpShowEvent()
        : analyticsController.sendSignInShowEvent();
    }
  }, [getFormState, getIsLoading, getRestoreState]);

  useEffect(() => {
    if (getRestoreState && getIsLoading) {
      return getPasswordMode
        ? analyticsController.sendNewPasswordShowEvent()
        : analyticsController.sendForgotShowEvent();
    }
  }, [getRestoreState, getPasswordMode, getIsLoading]);

  const detectTitle = () => {
    if (getRestoreState) {
      return "Reset Password";
    } else {
      return `Sign ${getFormState ? "up" : "in"}`;
    }
  };

  return (
    <ReduxProvider>
      <PageContext.Provider
        value={{
          translate: t,
          software: getParams.software,
          isCn: t("code") === "zh",
          analytics: analyticsController,
        }}
      >
        <GoogleOAuthProvider clientId={process.env.AUTH_GOOGLE_ID}>
          <Layout className="PgAuth-Page" isShowHelp>
            <Seo
              title={t(detectTitle())}
              description=""
              page=""
              manifest="browserconfig-index.xml"
              ogImg={OgImg}
            />
            <Form
              formState={getFormState}
              withTabs={
                !getRestoreState &&
                !getRedirectState &&
                !getMessageState.display &&
                !getLoaderMode
              }
              handleFormSwitch={switchFormState}
            >
              {getLoaderMode ? (
                <LoaderContainer
                  hash={getHash}
                  onSuccess={switchToPasswordRestore}
                  onFail={declinePasswordRestore}
                />
              ) : getMessageState.display ? (
                <Message
                  status={getMessageState.status}
                  error={getMessageState.error}
                  onSignIn={() => handleSwitchSignInAndRestore(false)}
                  onTryAgain={() => handleSwitchSignInAndRestore(true)}
                />
              ) : !getRestoreState ? (
                !getRedirectState ? (
                  <>
                    <SignContainer
                      regState={getFormState}
                      onSwitchForm={switchFormState}
                      onSuccess={onRegistrationSuccess}
                      onPasswordForgotten={() => setRestoreState(true)}
                    />
                    <SocialContainer
                      regState={getFormState}
                      onSuccess={onRegistrationSuccess}
                    />
                  </>
                ) : (
                  <ProgressContainer
                    onSuccess={handleSuccessAuth}
                    signUpState={getFormState}
                  />
                )
              ) : (
                <RestoreContainer
                  onPasswordRemember={() => setRestoreState(false)}
                  displayMessage={updateMessageDisplay}
                  passwordMode={getPasswordMode}
                  hash={getHash}
                />
              )}
            </Form>
          </Layout>
        </GoogleOAuthProvider>
      </PageContext.Provider>
    </ReduxProvider>
  );
};

export default Authorization;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "authorization" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
