import React, { useContext } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import Field from "../Field";
import OptionButton from "../OptionButton";
import Button from "../Button";
import Error from "../Error";
import { PageContext } from "../../../store/context/page-context";

const Sign = ({
  inputRef,
  email,
  password,
  passwordField,
  onEmailChange,
  onPasswordChange,
  reg,
  error,
  onSubmit,
  btnActive,
  handleBtnClick,
  onShowPassword,
  handleForgottenSwitch,
}) => {
  const context = useContext(PageContext);
  const t = context.translate;

  let errorBlock = <Error withBottom={!reg}>{error}</Error>;

  if (typeof error === "object") {
    errorBlock = (
      <Error withBottom={!reg}>
        {error[0]}{" "}
        <button onClick={handleBtnClick} className="clearBtn">
          {error[1]}
        </button>
        .
      </Error>
    );
  }

  return (
    <div className="PgAuth-Sign">
      <form className="PgAuth-Sign-form">
        <Field
          inputRef={inputRef}
          type="text"
          placeHolder="example@gmail.com"
          label={`${t("Your email")}:`}
          alert={error}
          currentValue={email}
          handleChange={onEmailChange}
          handleSuccess={onSubmit}
        />
        {!reg ? (
          <Field
            label={`${t("Your password")}:`}
            placeHolder="******************"
            type={passwordField}
            alert={error}
            currentValue={password}
            handleView={onShowPassword}
            handleChange={onPasswordChange}
            handleSuccess={onSubmit}
          >
            {error ? errorBlock : null}
            <OptionButton statusError={error} onClick={handleForgottenSwitch}>
              {t("I forgot my password")}
            </OptionButton>
          </Field>
        ) : error ? (
          errorBlock
        ) : null}
        <Button
          customClass={
            btnActive
              ? "PgAuth-Sign-form__btn"
              : "PgAuth-Sign-form__btn PgAuth-Button_disabled"
          }
          handleClick={onSubmit}
        >
          {t("Continue")}
        </Button>
        {reg && (
          <div className="PgAuth-Sign-form__switch">
            {t("If you already have an account, please ")}
            <button className="clearBtn" onClick={handleBtnClick}>
              {t("Sign in")}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

Sign.propTypes = {
  reg: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  passwordField: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  btnActive: PropTypes.bool.isRequired,
  handleBtnClick: PropTypes.func.isRequired,
  onShowPassword: PropTypes.func.isRequired,
  handleForgottenSwitch: PropTypes.func.isRequired,
};

export default Sign;
