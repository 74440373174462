import React, { useState, useEffect } from "react";
import Progress from "../../components/authorization/Progress";
import PropTypes from "prop-types";

const ProgressContainer = ({ onSuccess, signUpState }) => {
  const [getTimeLeft, setTimeLeft] = useState(2);

  const calculateTimeLeft = () => {
    setTimeLeft((prevState) => {
      if (prevState > 0) {
        return --prevState;
      }
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (getTimeLeft > 0) {
        calculateTimeLeft();
      } else {
        onSuccess();
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  return <Progress seconds={getTimeLeft} signUpState={signUpState} />;
};

ProgressContainer.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default ProgressContainer;
